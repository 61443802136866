import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { LinkButton } from "../../elements/linkbutton";

export const Footer = () => {
  const linkItems = [
    [
      {
        text: "Medium",
        link: "https://medium.com/@gram-voyage",
      },
      {
        text: "Whitepaper",
        link: "https://grampus-cwc.gitbook.io/gram_voyage/",
      },
      {
        text: "Terms of use",
        link: "https://cdn.norma.land/policy/TermsofUse_EN.html",
      },
      {
        text: "Privacy policy",
        link: "https://cdn.norma.land/policy/PrivacyPolicy_EN.html",
      },
      {
        text: "Support center",
        link: "https://normainmetaland.zendesk.com/",
      },
    ],
    [
      {
        text: "Connect us",
        link: "mailto:Contact@norma.land",
      },
    ],
  ];

  const socialItems = [
    {
      src: require("../../../assets/images/homepage/social_x_reverse.png"),
      link: "https://twitter.com/gram_voyage",
    },
    {
      src: require("../../../assets/images/homepage/social_d_reverse.png"),
      link: "https://discord.gg/gramecosystem",
    },
    {
      src: require("../../../assets/images/homepage/social_t_reverse.png"),
      link: "https://t.me/gram_voyage",
    },
    {
      src: require("../../../assets/images/homepage/social_m_reverse.png"),
      link: "https://medium.com/@gram-voyage",
    },
    {
      src: require("../../../assets/images/homepage/social_i_reverse.png"),
      link: "https://iq.wiki/kr/wiki/grampus-cwc",
    },
  ];

  return (
    <footer>
      <div className={cn("container", styles.container)} id="contact">
        <div className={styles.upper_container}>
          <div className={styles.left_container}>
            <Link to="/">
              <img className={styles.logo} src={require("../../../assets/images/homepage/footer_logo.png")} />
            </Link>
            <div className={styles.link_container}>
              {linkItems.map((column, index) => (
                <div className={styles.column} key={index}>
                  {column.map((item, index) => (
                    <span className={styles.link_item} key={index}>
                      <LinkButton link={item.link}>{item.text}</LinkButton>
                    </span>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right_container}>
            <div className={styles.social_container}>
              {socialItems.map((item, index) => (
                <LinkButton className={styles.social_button} link={item.link} key={index}>
                  <img src={item.src} alt="social" />
                </LinkButton>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.copyright}>COPYRIGHT (c) GRAMPUS CWC PTE. LTD. ALL RIGHTS RESERVED.</div>
      </div>
    </footer>
  );
};
